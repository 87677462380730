import {
  ClientContractChangeLogItemEntity,
  ClientContractEntity,
  ClientContractRevisionEntity,
  ClientCustomerEntity,
} from '@edgebox/api-rest-client';
import { DateTimeFormat, formatDate, InternalId } from '@edgebox/data-definition-kit';
import { ContractChangeLogItemPropertyNames, UserType } from '@edgebox/data-definitions';
import { HeaderCol, IconButton } from '@edgebox/react-components';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons/faInfoCircle';
import moment from 'moment';
import React, { useState } from 'react';
import { Alert, Col, Modal, Row } from 'react-bootstrap';
import {
  ApiComponent,
  DataDefinitionsEnumValue,
  IApiComponentState,
  PagedList,
  SiteLink,
  UserLink,
  ViewContractRevisionName,
} from '../../common';

interface IProps {
  backend?: boolean;
  forContract?: ClientContractEntity;
  forCustomer?: ClientCustomerEntity;
  emptyMessage?: React.ReactNode;
  emptyMessageWithNoFilters?: React.ReactNode;
}

interface IFilters {
  customerId?: InternalId;
  contractId?: InternalId;
}

interface IState extends IApiComponentState {
  filterContract?: ClientContractEntity;
  filterCustomer?: ClientCustomerEntity;
  details?: ClientContractChangeLogItemEntity;
}

function WithContractRevision(props: {
  entity: Promise<ClientContractRevisionEntity>;
  children: (revision: ClientContractRevisionEntity) => React.ReactElement;
}) {
  const [value, setValue] = useState<ClientContractRevisionEntity>();

  if (value) {
    return props.children(value);
  }

  props.entity.then((c) => setValue(c));

  return <span>...</span>;
}

const PROPERTY_NAMES: { [name in ContractChangeLogItemPropertyNames]: string } = {
  currentProductionSiteDomains: 'Used domains',
  licensedProductionSiteDomains: 'Licensed domains',
  renewAutomatically: 'Renew automatically',
  name: 'Name',
  minProdSites: 'Min licenses',
  maxProdSites: 'Max licenses',
  licensedSites: 'Licenses',
  currentProductionSites: 'Site count',
  currentStagingSites: 'Staging site count',
  currentTestingSites: 'Test site count',
  autoScaleLicenses: 'Auto scale licenses',
  endDate: 'Expiration date',
  archived: 'Archived',
};

type ExpectedPropertyTypes = undefined | null | string | string[] | moment.Moment | number | boolean;
function renderPropertyValue(value: ExpectedPropertyTypes, ignore: ExpectedPropertyTypes) {
  if (value === undefined || value === null || value === '') {
    return <em>none</em>;
  }
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }
  if (Array.isArray(value)) {
    return (
      <ul>
        {value.map((c) => (
          <li key={c} className={ignore && Array.isArray(ignore) && ignore.includes(c) ? 'text-muted' : undefined}>
            {c}
          </li>
        ))}
      </ul>
    );
  }
  if (moment.isMoment(value)) {
    return formatDate(value, DateTimeFormat.DateAndTime);
  }
  return value;
}

export class PagedContractChangeLogItemList extends ApiComponent<IProps, IState> {
  async load() {
    return {
      filterContract: this.props.forContract,
      filterCustomer: this.props.forCustomer,
    };
  }

  render() {
    const { backend, forCustomer, forContract, emptyMessage, emptyMessageWithNoFilters } = this.props;
    const { details } = this.state;

    const renderBackendProperties = this.api.currentUser?.type === UserType.Internal && backend;

    return (
      <>
        <PagedList<ClientContractChangeLogItemEntity, IFilters>
          searchable
          renderListHeader={() => (
            <Row>
              <HeaderCol xs={2}>Date</HeaderCol>
              <HeaderCol xs={2}>Initiator</HeaderCol>
              <HeaderCol xs={2}>Change</HeaderCol>
              <HeaderCol xs={3}>Affects</HeaderCol>
            </Row>
          )}
          request={(page, filter) =>
            this.api.billing.contractChangeLogItems.search(
              {
                contractId: filter?.contractId || forContract?.id,
                ...(renderBackendProperties
                  ? {
                      customerId: filter?.customerId || forCustomer?.id,
                    }
                  : {}),
              },
              { page, itemsPerPage: 5 }
            )
          }
          renderFilters={(onChange) => {
            return null;
          }}
          emptyMessageWithNoFilters={emptyMessageWithNoFilters}
          emptyMessage={emptyMessage || <Alert variant={'light'}>No changes.</Alert>}
          renderItem={(item) => (
            <Row className="mt-3" key={item.id}>
              <Col xs={2} title={item.createdAt.format('LLL')}>
                {item.createdAt.fromNow()}
              </Col>
              <Col xs={2} title={item.createdAt.format('LLL')}>
                {item.user?.getId() ? <UserLink entityId={item.user.getId()!} /> : <em>System</em>}
              </Col>
              <Col xs={2}>
                <DataDefinitionsEnumValue enumName={'ContractChangeLogItemType'} keyName={item.type} />
              </Col>
              <Col xs={5}>
                {item.site?.getId() ? (
                  <>
                    Site <SiteLink entityId={item.site.getId()!} />
                  </>
                ) : item.revision ? (
                  <WithContractRevision entity={item.revision.get() as Promise<ClientContractRevisionEntity>}>
                    {(entity) => (
                      <>
                        Revision{' '}
                        <em>
                          <ViewContractRevisionName entity={entity} includeDate />
                        </em>
                      </>
                    )}
                  </WithContractRevision>
                ) : (
                  <em>Contract</em>
                )}
              </Col>
              <Col xs={1}>
                {item.changes ? <IconButton icon={faInfoCircle} onClick={() => this.setState({ details: item })} /> : undefined}
              </Col>
            </Row>
          )}
        />
        {details && (
          <Modal show onHide={() => this.setState({ details: undefined })} scrollable size="lg">
            <Modal.Header closeButton>
              <Modal.Title>
                {details.createdAt.format('LLL')}:{' '}
                <DataDefinitionsEnumValue enumName={'ContractChangeLogItemType'} keyName={details.type} />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <HeaderCol xs={4} />
                <HeaderCol xs={4}>Previous</HeaderCol>
                <HeaderCol xs={4}>New</HeaderCol>
              </Row>
              {details.changes?.map((c, i) => (
                <Row key={i}>
                  <HeaderCol xs={4}>{PROPERTY_NAMES[c.name]}</HeaderCol>
                  <Col xs={4}>{renderPropertyValue(c.previousValue, c.newValue)}</Col>
                  <Col xs={4}>{renderPropertyValue(c.newValue, c.previousValue)}</Col>
                </Row>
              ))}
            </Modal.Body>
          </Modal>
        )}
      </>
    );
  }
}
